<template>
  <div
    v-if="show"
    ref="createGroup-modal"
    class="modal modal__right"
    id="createGroupModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="modalLabel"
    aria-hidden="true"
    data-backdrop="static"
  >
    <div class="modal__dialog" role="document">
      <div class="modal__content">
        <div class="modal__head">
          <div class="modal__head__icon">
            <a
              id="createGroupCloseButton"
              data-dismiss="modal"
              @click="clearGroupForm"
              class="modal__close modal__close--black"
            >
              <ion-icon name="arrow-back-outline"></ion-icon>
            </a>
          </div>
          <h5 class="modal__head__title text--capital">Creating a Group</h5>
        </div>
        <div class="modal__body">
          <form @submit="saveGroupForm">
            <div class="row">
              <div class="col-6">
                <div class="form__item">
                  <label class="form__label">Group Name</label>
                  <input
                    type="text"
                    class="input form__input form__input--lg"
                    placeholder="Enter Group Name"
                    v-model="groupForm.groupName"
                    v-bind:class="{
                      'input-required': groupErrorForm.groupName,
                    }"
                  />
                </div>
              </div>
              <div class="col-6">
                <div class="form__item">
                  <label class="form__label">Group Customer Type</label>
                  <select
                    v-model="groupForm.clientRoleKey"
                    class="select select--lg"
                    v-bind:class="{
                      'input-required': groupErrorForm.clientRoleKey,
                    }"
                    @change="getCustomFormFields('Groups', $event.target.value)"
                  >
                    <option
                      v-for="item in sortGroupType"
                      v-bind:key="item.customerTypeKey"
                      :value="item.customerTypeKey"
                    >
                      {{ item.clientTypeName }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <div v-if="groupForm.uniqueGroupId === null" class="form__item">
                  <label class="form__label">Generating ID...</label>
                </div>
                <div class="form__item">
                  <label class="form__label">Group ID</label>
                  <input
                    type="text"
                    class="input form__input form__input--lg"
                    placeholder="Enter Group ID"
                    v-model="groupForm.uniqueGroupId"
                    :disabled="groupForm.uniqueGroupId === ''"
                    v-bind:class="{
                      'input-required': groupErrorForm.uniqueGroupId,
                    }"
                  />
                </div>
              </div>
              <div class="col-6">
                <div class="form__item">
                  <!-- <label class="form__label">Group Email</label> -->
                  <div style="padding: 0px !important" class="col-12">
                    <label
                      style="padding: 0px !important"
                      class="form__label col-4"
                      >Email</label
                    >
                    <label
                      v-if="requiredEmail"
                      style="padding: 0px !important"
                      class="form__label col-8 text-danger"
                      >Email is required</label
                    >
                  </div>
                  <input
                    type="email"
                    class="input form__input form__input--lg"
                    placeholder="Enter Group email"
                    v-model="groupForm.groupEmailAddress"
                    @input="validateEmail(groupForm.groupEmailAddress)"
                    v-bind:class="{
                      'input-required': groupErrorForm.groupEmailAddress,
                    }"
                  />
                  <p
                    class="text--danger"
                    v-if="groupErrorForm.groupEmailAddress"
                  >
                    Please provide a valid email
                  </p>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <div class="form__item">
                  <label class="form__label">Group phone number</label>
                  <input
                    type="text"
                    class="input form__input form__input--lg"
                    placeholder="Enter Group phone number"
                    v-model="groupForm.groupPhoneNumber"
                    v-bind:class="{
                      'input-required': groupErrorForm.groupPhoneNumber,
                    }"
                  />
                </div>
              </div>
              <div class="col-6">
                <div class="form__item">
                  <label class="form__label">Group Mobile number</label>
                  <input
                    type="text"
                    class="input form__input form__input--lg"
                    placeholder="Enter Group mobile number"
                    v-model="groupForm.groupMobileNumber"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <div class="form__item">
                  <label class="form__label">Product Type</label>
                  <input
                    type="text"
                    class="input form__input form__input--lg"
                    placeholder="Enter Group Type"
                    readonly
                    value="Group"
                  />
                </div>
              </div>
            </div>

            <ul id="accordion" class="mb5">
              <div class="modal__accordion">
                <li
                  data-toggle="collapse"
                  class="sidebar-right__item modal__accordion--toggle"
                  data-target="#cggroupMembers"
                  aria-expanded="false"
                  aria-controls="cggroupMembers"
                >
                  <h5
                    class="w-100 mb-0 d-flex align-items-center justify-content-between"
                  >
                    Group Members
                    <ion-icon name="chevron-down-outline"></ion-icon>
                  </h5>
                </li>
                <div
                  id="cggroupMembers"
                  class="collapse"
                  data-parent="#accordion"
                >
                  <div class="pt-5">
                    <div
                      class="row"
                      v-for="item in groupForm.memberKeyArray"
                      v-bind:key="item"
                    >
                      <div class="form__item col-8">
                        <input
                          type="text"
                          :value="groupForm.memberNames[item]"
                          class="input form__input form__input--lg"
                          disabled
                        />
                      </div>
                      <div class="form__item col-3">
                        <button
                          v-on:click="deleteMemberFromGroup(item)"
                          type="button"
                          class="button page__body__header__button button--grey text--capital"
                        >
                          <span class="d-inline-flex mr-2">delete</span>
                          <img src="@/assets/img/delete.svg" alt="" srcset="" />
                        </button>
                      </div>
                    </div>
                    <div class="form__item">
                      <label class="form__label">Select Group Member</label>
                      <select
                        class="select select--lg"
                        v-model="selectedCustomerKey"
                      >
                        <option
                          v-for="item in allCustomers"
                          v-bind:key="item.customerKey"
                          :value="item.customerKey"
                        >
                          {{ item.customerNameWithID }}
                          <!-- {{ item.customerLastName }} -->
                        </option>
                      </select>
                    </div>
                    <div class="form__item" id="branch-area"></div>

                    <div class="text-right mt-3">
                      <button
                        @click="validateMultipleCustomer(selectedCustomerKey)"
                        type="button"
                        :disabled="enabled"
                        class="d-flex align-items-center ml-auto button button--grey text--600 text--black text--small text--capital"
                      >
                        {{
                          enabled ? "Please wait..." : "Add customer to group"
                        }}
                        <ion-icon
                          name="add-outline"
                          v-if="!enabled"
                          class="text--600 text--medium ml-2"
                        ></ion-icon>
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div class="modal__accordion">
                <li
                  data-toggle="collapse"
                  class="sidebar-right__item modal__accordion--toggle"
                  data-target="#cggroupRoles"
                  aria-expanded="false"
                  aria-controls="cggroupRoles"
                >
                  <h5
                    class="w-100 mb-0 d-flex align-items-center justify-content-between"
                  >
                    Group Roles
                    <ion-icon name="chevron-down-outline"></ion-icon>
                  </h5>
                </li>
                <div
                  id="cggroupRoles"
                  class="collapse"
                  data-parent="#accordion"
                >
                  <div class="pt-5">
                    <div
                      v-for="item in groupForm.memberRoles"
                      v-bind:key="
                        'g' + item.groupRoleNameKey + 'c' + item.clientKey
                      "
                    >
                      <div
                        class="row align-items-center"
                        v-if="
                          item.groupRoleNameKey != null &&
                          item.clientKey != null
                        "
                      >
                        <div class="col-5">
                          <div class="form__item">
                            <label class="form__label">Group Role</label>
                            <input
                              type="text"
                              class="input form__input form__input--lg"
                              :disabled="true"
                              :value="item.groupRoleName"
                            />
                          </div>
                        </div>
                        <div class="col-5">
                          <div class="form__item">
                            <label class="form__label">Members</label>
                            <input
                              type="text"
                              class="input form__input form__input--lg"
                              :disabled="true"
                              :value="item.customerName"
                            />
                          </div>
                        </div>
                        <div class="col-2">
                          <button
                            @click="
                              removeGroupRole(
                                item.clientKey,
                                item.groupRoleNameKey
                              )
                            "
                            type="button"
                            class="button button--cancel text--500 text--small text--capital"
                          >
                            delete
                          </button>
                        </div>
                      </div>
                    </div>

                    <div class="row align-items-center">
                      <div class="col-5">
                        <div class="form__item">
                          <label class="form__label">Group Roles</label>
                          <select
                            v-model="groupRoleAddModel.groupRoleNameKey"
                            class="select select--lg"
                            v-bind:class="{
                              'input-required': groupErrorForm.groupRole,
                            }"
                          >
                            <option
                              v-for="item in allGroupRoleNames"
                              v-bind:key="item.groupRoleNameKey"
                              :value="item.groupRoleNameKey"
                            >
                              {{ item.groupRoleName }}
                            </option>
                          </select>
                        </div>
                      </div>
                      <div class="col-5">
                        <div class="form__item">
                          <label class="form__label">Members</label>
                          <select
                            v-model="groupRoleAddModel.clientKey"
                            class="select select--lg"
                            v-bind:class="{
                              'input-required': groupErrorForm.groupRole,
                            }"
                          >
                            <option
                              v-for="item in groupForm.memberKeyArray"
                              v-bind:key="item"
                              :value="item"
                            >
                              {{ groupForm.memberNames[item] }}
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>

                    <div class="text-right mt-3">
                      <button
                        @click="addGroupRole()"
                        type="button"
                        class="d-flex align-items-center ml-auto button button--grey text--600 text--black text--small text--capital"
                        :disabled="
                          groupRoleAddModel.groupRoleNameKey == null &&
                          groupRoleAddModel.clientKey == null
                        "
                      >
                        Add Group Role
                        <ion-icon
                          name="add-outline"
                          class="text--600 text--medium ml-2"
                        ></ion-icon>
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div class="modal__accordion">
                <li
                  data-toggle="collapse"
                  class="sidebar-right__item modal__accordion--toggle"
                  data-target="#cgassociated"
                  aria-expanded="false"
                  aria-controls="cgassociated"
                >
                  <h5
                    class="w-100 mb-0 d-flex align-items-center justify-content-between"
                  >
                    Associated
                    <ion-icon name="chevron-down-outline"></ion-icon>
                  </h5>
                </li>
                <div
                  id="cgassociated"
                  class="collapse"
                  data-parent="#accordion"
                >
                  <div class="pt-5">
                    <div class="row">
                      <div class="col-6">
                        <div class="form__item">
                          <label class="form__label">Branch</label>
                          <select
                            class="select--lg w-100"
                            v-model="groupForm.assignedBranchKey"
                            v-bind:class="{
                              'input-required':
                                groupErrorForm.assignedBranchKey,
                            }"
                            @change="branchSelectChange($event)"
                          >
                            <option disabled>Select branch</option>
                            <option
                              v-for="item in allBranches"
                              v-bind:key="item.branchKey"
                              v-bind:value="item.branchKey"
                            >
                              {{ item.branchName }} ({{ item.branchId }})
                            </option>
                          </select>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="form__item">
                          <label class="form__label">Center</label>
                          <select
                            class="select--lg w-100"
                            v-model="groupForm.assignedCentreKey"
                            v-bind:class="{
                              'input-required':
                                groupErrorForm.assignedCentreKey,
                            }"
                          >
                            <option disabled>Select centre</option>
                            <option
                              v-for="item in branchCenter"
                              v-bind:key="item.centerKey"
                              v-bind:value="item.centerKey"
                            >
                              {{ item.centerName }}
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-6">
                        <div class="form__item">
                          <label class="form__label">Account Officer</label>
                          <select
                            class="select--lg w-100"
                            v-model="groupForm.assignedUserKey"
                            v-bind:class="{
                              'input-required': groupErrorForm.assignedUserKey,
                            }"
                          >
                            <option disabled>Select Account Officer</option>
                            <option
                              v-for="item in accountOfficers"
                              :key="item.userKey"
                              :value="item.userKey"
                            >
                              {{ item.name }}
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="modal__accordion"
                v-for="(set, index) in customFields"
                :key="set.customFieldSetKey"
                v-show="groupForm.clientRoleKey"
              >
                <li
                  data-toggle="collapse"
                  class="sidebar-right__item modal__accordion--toggle"
                  :data-target="`#ccSet${index}`"
                  aria-expanded="false"
                  aria-controls="ccprofileNote"
                >
                  <h5
                    class="w-100 mb-0 d-flex align-items-center justify-content-between"
                  >
                    {{ set.customFieldSetName }}
                    <ion-icon name="chevron-down-outline"></ion-icon>
                  </h5>
                </li>
                <div
                  :id="`ccSet${index}`"
                  class="collapse"
                  data-parent="#accordion"
                >
                  <div class="pt-5">
                    <div class="row">
                      <div
                        class="col-6"
                        v-for="(form, i) in set.customFields"
                        :key="form.customFieldKey"
                      >
                        <div class="form__item" v-show="isDefaultGroup(form)">
                          <label class="form__label">{{
                            form.customFieldName
                          }}</label>
                          <input
                            :type="
                              form.dataType === 'Number'
                                ? 'number'
                                : form.dataType === 'Date'
                                ? 'date'
                                : form.dataType === 'Checkbox'
                                ? 'checkbox'
                                : 'text'
                            "
                            v-if="
                              form.dataType !== 'Selection' &&
                              form.dataType.toLowerCase() !== 'user link' &&
                              form.dataType.toLowerCase() !== 'group link' &&
                              form.dataType.toLowerCase() !== 'customer link'
                            "
                            @input="
                              groupValidateNumber(
                                $event.target.value,
                                form.dataType,
                                index,
                                i
                              )
                            "
                            :id="form.customFieldKey"
                            :ref="form.customFieldKey"
                            class="input input--right form__input form__input--lg"
                            v-bind:class="{
                              'input-required': form.customFieldNameErr,
                            }"
                            :placeholder="`Enter ${form.customFieldName}`"
                          />
                          <select
                            class="select--lg w-100"
                            v-if="
                              form.dataType === 'Selection' &&
                              form.dataType.toLowerCase() !== 'user link' &&
                              form.dataType.toLowerCase() !== 'group link' &&
                              form.dataType.toLowerCase() !== 'customer link'
                            "
                            v-bind:class="{
                              'input-required': form.customFieldNameErr,
                            }"
                            :id="form.customFieldKey"
                            :ref="form.customFieldKey"
                          >
                            <option disabled selected value="null">
                              Select {{ form.customFieldName }}
                            </option>
                            <option
                              v-for="opt in form.customFieldSelections"
                              :key="opt.customFieldSelectionKey"
                              :value="opt.value"
                            >
                              {{ opt.value }}
                            </option>
                          </select>

                          <!-- User Multi select -->
                          <multiselect
                            v-model="form.customFieldValue"
                            :id="'cus-' + form.customFieldKey"
                            :ref="form.customFieldKey"
                            label="name"
                            track-by="userKey"
                            placeholder="Type to search user"
                            open-direction="bottom"
                            :options="sortedUsers"
                            :multiple="false"
                            :searchable="true"
                            :internal-search="true"
                            :clear-on-select="false"
                            :close-on-select="true"
                            :options-limit="300"
                            :limit="3"
                            :max-height="800"
                            :show-no-results="false"
                            :hide-selected="true"
                            v-if="form.dataType.toLowerCase() === 'user link'"
                            v-bind:class="{
                              'input-required': form.customFieldNameErr,
                            }"
                          >
                            <template
                              slot="tag"
                              slot-scope="{ option, remove }"
                            >
                              <span class="custom__tag">
                                <span>{{ option.name }}</span>
                                <span
                                  class="custom__remove"
                                  @click="remove(option)"
                                  >❌</span
                                >
                              </span>
                            </template>
                            <span slot="noOptions">Type to display list.</span>
                          </multiselect>

                          <!-- Group multiselect -->
                          <multiselect
                            v-model="form.customFieldValue"
                            :id="'cus-' + form.customFieldKey"
                            :ref="form.customFieldKey"
                            label="groupName"
                            track-by="groupKey"
                            placeholder="Type to search group"
                            open-direction="bottom"
                            :options="sortedGroups"
                            :multiple="false"
                            :searchable="true"
                            :internal-search="true"
                            :clear-on-select="false"
                            :close-on-select="true"
                            :options-limit="300"
                            :limit="3"
                            :max-height="800"
                            :show-no-results="false"
                            :hide-selected="true"
                            v-if="form.dataType.toLowerCase() === 'group link'"
                            v-bind:class="{
                              'input-required': form.customFieldNameErr,
                            }"
                          >
                            <template
                              slot="tag"
                              slot-scope="{ option, remove }"
                            >
                              <span class="custom__tag">
                                <span>{{ option.name }}</span>
                                <span
                                  class="custom__remove"
                                  @click="remove(option)"
                                  >❌</span
                                >
                              </span>
                            </template>
                            <span slot="noOptions">Type to display list.</span>
                          </multiselect>

                          <!-- Customer multiselect -->
                          <multiselect
                            v-model="form.customFieldValue"
                            :id="'cus-' + form.customFieldKey"
                            :ref="form.customFieldKey"
                            label="customerFirstName"
                            track-by="customerKey"
                            placeholder="Type to search customer"
                            open-direction="bottom"
                            :options="sortedCustomers"
                            :multiple="false"
                            :searchable="true"
                            :internal-search="true"
                            :clear-on-select="false"
                            :close-on-select="true"
                            :options-limit="300"
                            :limit="3"
                            :max-height="800"
                            :show-no-results="false"
                            :hide-selected="true"
                            v-if="
                              form.dataType.toLowerCase() === 'customer link'
                            "
                            v-bind:class="{
                              'input-required': form.customFieldNameErr,
                            }"
                          >
                            <template
                              slot="singleLabel"
                              slot-scope="{ option }"
                            >
                              <span>{{ option.customerFirstName }}</span>
                              <span>&nbsp;{{ option.customerLastName }}</span>
                            </template>

                            <template slot="option" slot-scope="{ option }">
                              <div class="option__desc">
                                <span class="option__title">
                                  {{ option.customerFirstName }}&nbsp;
                                </span>
                                <span class="option__small">{{
                                  option.customerLastName
                                }}</span>
                              </div>
                            </template>
                            <span slot="noOptions">Type to display list.</span>
                          </multiselect>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="modal__accordion">
                <li
                  data-toggle="collapse"
                  class="sidebar-right__item modal__accordion--toggle"
                  data-target="#cgprofileNote"
                  aria-expanded="false"
                  aria-controls="cgprofileNote"
                >
                  <h5
                    class="w-100 mb-0 d-flex align-items-center justify-content-between"
                  >
                    Profile Note
                    <ion-icon name="chevron-down-outline"></ion-icon>
                  </h5>
                </li>
                <div
                  id="cgprofileNote"
                  class="collapse"
                  data-parent="#accordion"
                >
                  <div class="pt-5">
                    <div class="form__item">
                      <textarea
                        cols="5"
                        rows="5"
                        class="input form__input"
                        placeholder="Profile Note"
                        v-model="groupForm.groupNotes"
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>
            </ul>
            <div class="form__item">
              <div class="form__action">
                <button
                  type="submit"
                  class="button form__button form__button--lg w-30 save-changes"
                  v-if="!isSaving"
                >
                  Save group
                </button>
                <a
                  role="button"
                  class="button form__button form__button--lg w-25 modal__confirm__button modal__confirm__button--delete"
                  v-if="isSaving"
                  :disabled="true"
                >
                  <div class="spinner-border text-light" role="status"></div>
                </a>
                <a
                  data-dismiss="modal"
                  v-if="!isSaving"
                  role="button"
                  class="modal__close form__action__text form__action__text--danger text--capital ml6"
                  @click="clearGroupForm"
                >
                  cancel
                </a>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Multiselect from "vue-multiselect";
import branchesService from "@/core/services/organization-service/branches.service";
import centresService from "@/core/services/organization-service/centres.service";
import AccessApiService from "@/core/services/access.service";
import GeneralApiService from "@/core/services/general.service";
import { GET_GROUP_TYPES } from "@/core/services/store/deposit.module";
import groupService from "@/core/services/general-setup-service/group.service";
import customerService from "@/core/services/general-setup-service/customer.service";
import fieldSetService from "@/core/services/fieldset-service/fieldset.service";

export default {
  name: "CreateGroup",
  data() {
    return {
      selectedCustomerKey: null,
      groupForm: {
        assignedBranchKey: "",
        assignedCentreKey: "",
        assignedUserKey: "",
        clientRoleKey: "",
        groupEmailAddress: "",
        groupName: "",
        groupPhoneNumber: "",
        uniqueGroupId: "",
        loanCycle: 0,
        groupMobileNumber: "",
        groupNotes: "",
        createdBy: null,
        groupClientRoles: [],
        memberKeySet: new Set(),
        memberNames: {},
        memberRoles: [],
        memberKeyArray: [],
      },
      allUsers: [],
      groupErrorForm: {
        clientRoleKey: false,
        groupEmailAddress: false,
        groupName: false,
        groupPhoneNumber: false,
        uniqueGroupId: false,
        loanCycle: false,
        groupMobileNumber: false,
        groupNotes: false,
        createdBy: false,
      },
      groupRoleAddModel: {
        groupRoleNameKey: null,
        clientKey: null,
      },
      allActiveCustomers: [],
      userLoading: false,
      isSaving: false,
      customFields: [],
      allCentres: [],
      accountOfficers: [],
      allBranches: [],
      branchCenter: [],
      enabled: false,
      requiredEmail: false,
      allCustomers: [],
      allGroupRoleNames: [],
      grNameDict: null,
      internalControl: null,
    };
  },
  props: {
    show: Boolean,
    close: Function,
  },
  components: {
    Multiselect,
  },
  computed: {
    ...mapState({
      sortedCustomers() {
        const userArray = this.allActiveCustomers;
        return userArray.sort((a, b) =>
          `${a.customerFirstName} ${a.customerLastName}`.localeCompare(
            `${b.customerFirstName} ${b.customerLastName}`
          )
        );
      },
      groupClientTypes: (state) =>
        state.deposit?.groupTypes?.data?.items?.filter(
          (x) => x.clientType == "Group"
        ),

      sortGroupType() {
        let sorted = [];
        if (this.groupClientTypes) {
          sorted = this.groupClientTypes.slice().sort((a, b) => {
            let textA = a.clientTypeName.toUpperCase();
            let textB = b.clientTypeName.toUpperCase();
            return textA < textB ? -1 : textA > textB ? 1 : 0;
          });
        }

        return sorted;
      },

      sortedUsers() {
        const userArray = this.allUsers;
        return userArray.sort((a, b) => a.name.localeCompare(b.name));
      },
    }),
  },
  methods: {
    getGroupType() {
      this.$store.dispatch(GET_GROUP_TYPES);
    },
    async getAllUsers() {
      this.allUsers = [];
      try {
        this.userLoading = true;
        const userModel = {
          pageNumber: 1,
          pageSize: 1000000,
          branchKey: "",
          status: true,
          filters: [],
        };
        AccessApiService.post(
          "ProleanUser/GetUsersWithoutPermission",
          userModel
        ).then((resp) => {
          this.allUsers = resp.data.data;
          this.userLoading = false;
        });
      } catch (e) {
        const error = e.response.data.message;
        this.userLoading = false;
        this.$emit("alertError", error);
      }
    },
    validateGroupForm() {
      this.clearGroupValidation();
      let validateGroupRoleMember = [];
      this.groupForm.memberRoles.forEach((item) => {
        if (!item.groupRoleName && !item.groupRoleNameKey) {
          validateGroupRoleMember.push(false);
        } else {
          validateGroupRoleMember.push(true);
        }
      });

      const assignedBranchKey = this.internalControl.assignmentConstraints
        .branch
        ? this.groupForm.assignedBranchKey
        : true;
      const assignedCentreKey = this.internalControl.assignmentConstraints
        .centre
        ? this.groupForm.assignedCentreKey
        : true;
      const assignedUserKey = this.internalControl.assignmentConstraints
        .accountOfficer
        ? this.groupForm.assignedUserKey
        : true;

      if (
        this.groupForm.groupName &&
        this.groupForm.uniqueGroupId &&
        this.groupForm.clientRoleKey &&
        validateGroupRoleMember.includes(true) &&
        this.groupForm.groupPhoneNumber &&
        assignedBranchKey &&
        assignedCentreKey &&
        assignedUserKey
      ) {
        return true;
      }

      if (!this.groupForm.groupName) {
        this.groupErrorForm.groupName = true;
      }
      if (!this.groupForm.uniqueGroupId) {
        this.groupErrorForm.uniqueGroupId = true;
      }
      if (!this.groupForm.groupEmailAddress) {
        this.groupErrorForm.groupEmailAddress = true;
      }
      if (!this.groupForm.groupPhoneNumber) {
        this.groupErrorForm.groupPhoneNumber = true;
      }
      if (!this.groupForm.clientRoleKey) {
        this.groupErrorForm.clientRoleKey = true;
      }
      if (!validateGroupRoleMember.includes(true)) {
        this.groupErrorForm.groupRole = true;
      }
      if (!assignedBranchKey) {
        this.groupErrorForm.assignedBranchKey = true;
      }
      if (assignedBranchKey) {
        if (!assignedCentreKey) {
          this.groupErrorForm.assignedCentreKey = true;
        }
      }
      if (assignedBranchKey) {
        if (!assignedUserKey) {
          this.groupErrorForm.assignedUserKey = true;
        }
      }
      return false;
    },
    validateMultipleCustomer(key) {
      this.enabled = true;
      GeneralApiService.post(
        `Group/FindIfCustomerCanBeAddedToMultipleGroup?customerKey=${key}`
      )
        .then((response) => {
          if (response.data.status) {
            this.enabled = false;
            this.addMemberToGroup(key);
          } else {
            this.enabled = false;
            this.selectedCustomerKey = "";
            this.$emit(
              "alertError",
              "This customer already belongs to another group and cannot be added."
            );
          }
        })
        .catch(() => {
          this.enabled = false;
          this.selectedCustomerKey = "";
          this.$emit(
            "alertError",
            "This customer already belongs to another group and cannot be added."
          );
        });
    },
    clearGroupValidation() {
      this.groupErrorForm = {
        clientRoleKey: false,
        groupEmailAddress: false,
        groupName: false,
        groupPhoneNumber: false,
        uniqueGroupId: false,
        loanCycle: false,
        groupMobileNumber: false,
        groupNotes: false,
        createdBy: false,
        groupRole: false,
      };
    },
    groupValidateNumber(num, type, fieldSetIndex, fieldIndex) {
      if (type.toLowerCase() === "number") {
        if (isNaN(parseFloat(num))) {
          this.$emit("alertWarning", "Invalid input value, Number required.");
          const key =
            this.customFields[fieldSetIndex].customFields[fieldIndex]
              .customFieldKey;
          this.$refs[key][0].value = 0;
        }
      }
    },
    validateEmail(email) {
      let validRegex1 = /\S+@\S+\.\S+/;
      if (!email.trim().match(validRegex1)) {
        this.groupErrorForm.groupEmailAddress = true;
        return this.$emit("alertWarning", "Please provide a valid email.");
      } else {
        this.groupErrorForm.groupEmailAddress = false;
      }
    },
    clearGroupForm() {
      this.requiredEmail = false;
      this.groupForm = {
        assignedBranchKey: "",
        assignedCentreKey: "",
        assignedUserKey: "",
        clientRoleKey: "",
        groupEmailAddress: "",
        groupName: "",
        groupPhoneNumber: "",
        uniqueGroupId: "",
        loanCycle: 0,
        groupMobileNumber: "",
        groupNotes: "",
        createdBy: null,
        groupClientRoles: [],
        memberKeySet: new Set(),
        memberNames: {},
        memberRoles: [],
        memberKeyArray: [],
      };
      this.groupRoleAddModel = {
        groupRoleNameKey: null,
        clientKey: null,
      };
      this.groupForm.customFieldValues = [];
      this.getCustomFormFields("Groups", null);
    },
    isDefaultGroup(field) {
      let check = field.customFieldLinks.find(
        (link) => link.entityLinkedKey === this.groupForm.clientRoleKey
      );
      if (check) {
        return check.isDefault;
      }
      return false;
    },
    saveGroupForm: async function (e) {
      e.preventDefault();
      if (this.groupErrorForm.groupEmailAddress) {
        this.$emit("alertWarning", "Please provide a valid email.");
        return;
      }
      if (this.validateGroupForm()) {
        try {
          this.isSaving = true;
          if (!this.groupForm.groupEmailAddress && this.requiredEmail) {
            this.$emit("alertWarning", "Email field is required.");
            return;
          }
          this.groupForm.customFieldValues = [];
          let cForm = [];
          let isValidate = false;
          if (this.customFields.length > 0) {
            this.customFields.forEach((formSet) => {
              formSet.customFields.forEach((form) => {
                let key = form.customFieldKey;
                let value = this.$refs[key][0].value;

                if (
                  ["customer link", "group link", "user link"].includes(
                    form.dataType.toLowerCase()
                  ) &&
                  value.length == 0
                ) {
                  value = "";
                }
                if (form.dataType.toLowerCase() === "checkbox") {
                  value = this.$refs[key][0].checked;
                }

                let required = form.customFieldLinks.find(
                  (item) =>
                    item.entityLinkedKey === this.groupForm.clientRoleKey
                );
                if (!value && required && required.isRequired) {
                  this.$emit(
                    "alertWarning",
                    `Some custom field value are required`
                  );
                  isValidate = true;
                  form.customFieldNameErr = true;
                  return;
                } else {
                  form.customFieldNameErr = false;
                }

                if (
                  this.$refs[key].length &&
                  this.$refs[key][0].value &&
                  value
                ) {
                  const formValObj = {
                    customFieldKey: key,
                    scoreAmount: 0,
                    indexInList: 0,
                    customFieldSetGroupIndex: 0,
                    customFieldValue:
                      value.userKey ||
                      value.customerKey ||
                      value.groupKey ||
                      value,
                  };
                  cForm.push({ ...formValObj });
                } else {
                  this.groupForm.customFieldValues = [];
                }
                this.groupForm.customFieldValues = cForm;
              });
            });
          }
          if (isValidate) {
            this.isSaving = false;
            return;
          }
          // this.groupForm.groupClientRoles = this.groupForm.memberRoles;
          this.groupForm.members = [];
          const memberRoleDict = {};
          this.groupForm.memberRoles.forEach((row) => {
            if (!memberRoleDict.hasOwnProperty(row.clientKey)) {
              memberRoleDict[row.clientKey] = [];
            }
            if (row.groupRoleNameKey != null) {
              memberRoleDict[row.clientKey].push(row.groupRoleNameKey);
            }
          });
          for (const clientKey in memberRoleDict) {
            this.groupForm.members.push({
              clientKey: clientKey,
              roleNameKeys: memberRoleDict[clientKey],
            });
          }

          const response = await groupService.createGroup(this.groupForm);
          this.clearGroupForm();
          this.isSaving = false;
          document.getElementById("createGroupCloseButton").click();
          this.$emit("alertSuccess", "Group Created.");
          this.pushToGroupPage(response.data.data);
        } catch (e) {
          this.isSaving = false;
          const error = e.response.data.message;
          this.$emit("alertError", error);
        }
      } else {
        this.$emit("alertWarning", "Some form fields are required.");
      }
    },
    pushToGroupPage(data) {
      this.$router.push({ path: `/group/${data}` });
    },
    async getCustomers() {
      try {
        this.isLoading = true;
        const res = await customerService.getCustomers({
          customerTypeKey: null,
          filters: [],
          pageIndex: 1,
          pageSize: 1000,
          customerStatus: null,
        });
        this.allCustomers = res.data.data.items;
        this.isLoading = false;
      } catch (e) {
        const error = e.response.data.message;
        this.$emit("alertError", error);
      }
    },
    async addMemberToGroup(customerKey) {
      if (!this.groupForm.memberKeySet.has(customerKey)) {
        const c = this.allCustomers.filter((x) => x.customerKey == customerKey);
        if (c.length > 0) {
          const customer = c[0];
          let name = `${customer.customerNameWithID}`;

          this.groupForm.memberKeySet.add(customerKey);
          this.groupForm.memberNames[customerKey] = name;
          this.groupForm.memberRoles.push({
            groupRoleNameKey: null,
            groupRoleName: null,
            clientKey: customerKey,
            customerName: name,
          });
          this.groupForm.memberKeyArray = Array.from(
            this.groupForm.memberKeySet
          );
          this.selectedCustomerKey = "";
        }
      } else {
        this.$emit("alertWarning", "Member name already exist.");
      }
    },
    deleteMemberFromGroup(item) {
      this.groupForm.memberKeySet.delete(item);
      delete this.groupForm.memberNames[item];
      this.groupForm.memberRoles = this.groupForm.memberRoles.filter(
        (x) => x.clientKey != item
      );
      this.groupForm.memberKeyArray = Array.from(this.groupForm.memberKeySet);
    },
    async getGroupRoleNames() {
      try {
        this.isLoading = true;
        const res = await groupService.getGroupRoleNames({
          pageSize: 100000,
          pageIndex: 1,
        });
        this.allGroupRoleNames = res.data.data.data;
        let dictionary = Object.assign(
          {},
          ...this.allGroupRoleNames.map((x) => ({
            [x.groupRoleNameKey]: x.groupRoleName,
          }))
        );
        this.grNameDict = dictionary;
        this.isLoading = false;
      } catch (e) {
        if (e.response) {
          const error = e.response.data.message;
          this.$emit("alertError", error);
        }
      }
    },
    addGroupRole() {
      if (
        this.groupRoleAddModel.groupRoleNameKey &&
        this.groupRoleAddModel.clientKey
      ) {
        let groupRoleName = this.allGroupRoleNames.find(
          (role) =>
            role.groupRoleNameKey === this.groupRoleAddModel.groupRoleNameKey
        );
        this.groupForm.memberRoles.forEach((item) => {
          if (item.clientKey === this.groupRoleAddModel.clientKey) {
            item.groupRoleNameKey = this.groupRoleAddModel.groupRoleNameKey;
            item.groupRoleName = groupRoleName.groupRoleName;
          }
        });
        this.groupRoleAddModel.groupRoleNameKey = "";
        this.groupRoleAddModel.clientKey = "";
      }
    },
    // async getAllCustomers() {
    //   this.allActiveCustomers = [];
    //   try {
    //     this.userLoading = true;
    //     const userModel = {
    //       pageIndex: 1,
    //       pageSize: 100000,
    //       filters: [],
    //       customerStatus: "Active"
    //     }
    //     GeneralApiService.post('Customer/customers', userModel)
    //     .then((resp) => {
    //       this.allActiveCustomers = resp.data.data.items;
    //       this.userLoading = false;
    //     })
    //   } catch(e) {
    //     const error = e.response.data.message;
    //     this.userLoading = false;
    //     this.$emit("alertError", error);
    //   }
    // },
    async getCustomFormFields(type, key) {
      this.customFields = [];
      try {
        if (type === "Customers" && key !== null) {
          this.requiredBVN = this.customerClientTypes.find(
            (item) => key === item.customerTypeKey
          ).requireBvn;
          this.requiredEmployer = this.customerClientTypes.find(
            (item) => key === item.customerTypeKey
          ).requireEmployer;
          this.requiredEmail = this.customerClientTypes.find(
            (item) => key === item.customerTypeKey
          ).requireEmailAddress;
          this.generateCustomerId(key);
        } else if (type === "Groups" && key !== null) {
          this.generateGroupId(key);
          this.requiredEmail = this.groupTypes.find(
            (item) => key === item.customerTypeKey
          ).requireEmailAddress;
        }
        this.isLoading = true;
        const model = {
          customFieldSetType: type,
          entityLinkedKey: key,
        };
        const res = await fieldSetService.getFormFieldsByType(model);
        this.customFields = res.data.data.items;
        this.isLoading = false;
      } catch (e) {
        const error = e?.response?.data?.message;
        this.$emit("alertWarning", error);
      }
    },
    generateGroupId(key) {
      this.groupForm.uniqueGroupId = null;
      GeneralApiService.get(`Group/GetUniqueIdForGroup?groupTypeKey=${key}`)
        .then((response) => {
          this.groupForm.uniqueGroupId = response.data.message;
        })
        .catch(() => {
          this.groupForm.uniqueGroupId = "";
        });
    },
    getCustom(customerTypesKey) {
      if (customerTypesKey) {
        return this.getCustomFormFields("Groups", customerTypesKey);
      }
      return;
    },
    branchSelectChange(event) {
      const branchKey = event.target.value;
      this.getCustomerAccountOfficer(branchKey);
      this.getBranchCenters(branchKey);
    },
    getCustomerAccountOfficer(key) {
      this.accountOfficers = [];
      AccessApiService.get(
        `ProleanUser/GetBranchClientOfficer?branchKey=${key}`
      ).then((resp) => {
        this.accountOfficers = resp.data.data;
      });
    },
    async getBranchCenters(key) {
      const model = {
        searchParam: null,
        branchKey: key,
        pageIndex: 1,
        pageSize: 100,
      };
      try {
        const res = await centresService.search(model);
        this.branchCenter = [];
        this.branchCenter = res.data.data.items;
        // this.isLoading = false;
      } catch (e) {
        // this.isLoading = false;
        const error = e.response.data.message;
        this.$emit("alertWarning", error);
      }
    },
    async getBranches() {
      try {
        this.isLoading = true;
        const res = await branchesService.get({
          branchName: null,
          branchState: "Active",
          sortColumn: "",
          isDescending: false,
          pageIndex: 1,
          pageSize: 100,
        });
        this.allBranches = res.data.data.items;
        this.isLoading = false;
      } catch (e) {
        const error = e.response.data.message;
        this.$emit("alertWarning", error);
      }
    },
    updateEmployer() {
      this.customerForm.employeeKey = this.employer.employerKey;
    },
    async getCentres() {
      this.allCentres = [];
      try {
        this.isLoading = true;
        const res = await centresService.get({
          centreName: null,
          centreStatus: "Active",
          pageIndex: 1,
          pageSize: 100,
          sortColumn: "",
          isDescending: false,
        });
        this.allCentres = res.data.data.items;
        this.isLoading = false;
      } catch (e) {
        const error = e.response.data.message;
        this.$emit("alertWarning", error);
      }
    },
    getAllEmployers() {
      const paged = {
        pageIndex: 1,
        pageSize: 99999,
      };

      GeneralApiService.post("Employer/Employers", paged)
        .then((res) => res.data.data.items)
        .then((res) => {
          this.allEmployers = res;
        });
    },
    getInternalControl() {
      GeneralApiService.get("InternalControls").then((resp) => {
        this.internalControl = resp.data.data;
      });
    },
  },
  watch: {
    show(change) {
      if (change) {
        // this.getIdTemplates();
        // this.getCustomerTypes();
        this.getBranches();
        this.getCentres();
        this.getAllEmployers();
        this.getInternalControl();
        this.getCustomers();
        this.getGroupRoleNames();
        this.getAllUsers();
      }
    },
  },
};
</script>
